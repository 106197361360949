.email-template-container {
  max-width: 1000px;
  width: 100%;
  max-height: 721px;
  height: 100%;
}

.email-template-container .sun-editor .se-wrapper .se-wrapper-inner {
  min-width: 60vw;
  width: 100%;
  height: 100% !important;
  min-height: 47vh !important;
  overflow-y: auto;
  overflow-x: auto;
}

.email-template-done-btn {
  background: #4f46e5;
  color: white;
  border: 2px solid #cbd6e1;
  padding: 10px 35px;
  border-radius: 30px;
}
