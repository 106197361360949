
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.hidden {
  display: none;
}
.show {
  display: block;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.auth-wrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: left;
  margin-top: 50px;
}
.auth-inner {
  width: 450px;
  margin: auto;
  background: #ffffff;
  box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
  padding: 40px 55px 45px 55px;
  border-radius: 15px;
  transition: all 0.3s;
}
.auth-wrapper .form-control:focus {
  border-color: #167bff;
  box-shadow: none;
}
.auth-wrapper h3 {
  text-align: center;
  margin: 0;
  line-height: 1;
  padding-bottom: 20px;
}
.custom-control-label {
  font-weight: 400;
}
.forgot-password,
.forgot-password a {
  text-align: right;
  font-size: 13px;
  padding-top: 10px;
  color: #7f7d7d;
  margin: 0;
}
.forgot-password a {
  color: #167bff;
}
.client_selected {
  background-color: #f0f0f0; /* Example background color */
}
.uploaded-image-wrapper {
  pointer-events: none; /* Disable pointer events for uploaded image wrappers */
}
/* settings page */
.circular-input {
  border-radius: 50%;
}

.nav-tabs .nav-link {
  color: #727272 !important;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #000000 !important;
  border-bottom: 1px solid #4f46e5 !important;
}

a#tab1.active {
  color: #000000 !important;
}

.nav-tabs {
  border-bottom: none !important;
}

.company-setting-box {
  max-width: 1042px;
  width: 100%;
  box-shadow: 0px 0px 6px #00000029;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 5px;
  opacity: 1;
  padding: 50px 30px !important;
  margin: auto;
}

.fs30 {
  font-size: 30px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}

.border-right {
  border-right: 1px solid #cbd6e1;
  opacity: 1;
}

.fs24 {
  font-size: 24px;
  text-align: left;
  letter-spacing: 0px;
  color: #000000;
  font-weight: bold;
  opacity: 1;
}

.fs16 {
  font-size: 16px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}
.payment_tab {
  max-width: 500px;
  height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.payment_tab span {
  font-size: 17px;
  font-weight: 400;
}
.MainLogo {
  left: 805px;
  width: 115px;
  text-align: center;
  height: 100px;
  padding: 21px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px dashed #94a3b8;
  border-radius: 5px;
  opacity: 1;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.SecondaryLogo {
  top: 1226px;
  left: 961px;
  max-width: 239px;
  width: 100%;
  height: 100px;
  padding: 15px;
  margin-right: -20px;

  /* UI Properties */
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px dashed #94a3b8;
  border-radius: 5px;
  opacity: 1;
}
.DefaultHeaderlogo {
  width: 100%;
  top: 1410px;
  left: 806px;
  padding: 50px;
  text-align: center;
  /* height: 165px; */
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px dashed #94a3b8;
  border-radius: 5px;
  opacity: 1;
}

.margin-top-80 {
  margin-top: 80px;
}

.form-control {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #cbd6e1;
  border-radius: 10px;
  opacity: 1;
  min-height: 60px !important;
  /* width: 440px; */
}

.btn-primary {
  background-color: #4f46e5;
}

.text-content {
  text-align: center;
  font: normal normal normal 12px/17px Open Sans;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  font-weight: 400;
  margin-top: 5px;
}
.SecondaryLogopath {
  padding: 10px;
  opacity: 1;
  width: 60px !important;
  height: 60px;
  object-fit: contain;
}
.DefaultHeaderlogoImg {
  width: 80px;
  height: 80px;
  object-fit: contain;
}
.HeaderPreview {
  height: 144px;
  text-align: -webkit-center;
  border-bottom: 1px solid #c1c1c1;
  position: relative;
  opacity: 1;
}
.CUSTOMERLOGO {
  top: 0px;
  left: 1472px;
  width: 44px;
  height: 22px;
  margin-top: 16px;
  text-align: center;
  font: normal normal bold 8px/11px Open Sans;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}
.headermainsection {
  overflow: hidden;
  top: 1220px;
  left: 1248px;
  /* width: 498px; */
  min-height: 476px;
  /* height: 100%; */
  padding: 20px 0px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 2px solid #c1c1c1;
  opacity: 1;
}
.center-cirlce-header {
  width: 66px;
  position: relative;
  border-radius: 50%;
  height: 66px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 4px solid #c1c1c1;
  opacity: 1;
  top: 5px;
}

.section1 {
  top: 1377px;
  left: 1293px;
  width: 173px;
  height: 9px;
  /* UI Properties */
  background: #c1c1c1 0% 0% no-repeat padding-box;
  opacity: 1;
}
.section2 {
  top: 1399px;
  left: 1293px;
  width: 290px;
  height: 9px;
  /* UI Properties */
  background: #c1c1c1 0% 0% no-repeat padding-box;
  opacity: 1;
}
.section3 {
  top: 1421px;
  left: 1293px;
  width: 258px;
  height: 9px;
  /* UI Properties */
  background: #c1c1c1 0% 0% no-repeat padding-box;
  opacity: 1;
}
.section4 {
  top: 1443px;
  left: 1293px;
  width: 416px;
  height: 9px;
  /* UI Properties */
  background: #c1c1c1 0% 0% no-repeat padding-box;
  opacity: 1;
}

p.p-fs-10 {
  position: relative;
  top: 32px;
  font-size: 10px;
  font-weight: 800;
}

p.p-fs-15 {
  bottom: -16px;
  font-size: 9px;
  position: relative;
}

span.headerinner {
  position: relative;
  font-size: 12px;
  float: left;
  left: 34px;
  font-weight: 500;
}

span.innserimage {
  position: relative;
  font-size: 12px;
  float: right;
  left: -15px;
  font-weight: 500;
}

p.cricledot {
  border-radius: 50%;
  background: black;
  width: 18px;
  height: 18px;
  position: relative;
  top: 22px;
}

.m-2.border-custom-colour {
  border: 1px solid;
  width: 50%;
}

p.cricledot {
  border: 12px;
  border-radius: 50%;
  background: #191c24;
  width: 20px;
  position: relative;
  display: inline-block;
  height: 20px;
  top: 0px;
}
.m-2.border-custom-colour.d-flex {
  border: 1px solid;
  width: 50%;
  padding: 6px;
  height: 30px;
  column-gap: 10px;
  justify-content: center;
}

.text-primary {
  color: #4f46e5 !important;
}
.bg-primary {
  color: #4f46e5 !important;
}
span.fs-15 {
  font-weight: bold;
  font-size: 16px;
}

.card.paymentgateway {
  height: 197px;
  background: #ffffff 0% 0% no-repeat padding-box;
  opacity: 1;
}

.paymentgatewaybtn {
  width: 335px;
  height: 53px;
  background: #4f46e5 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 6px #00000029;
  opacity: 1;
  padding: 5px;
  margin-left: -12px;
}

.paymentgateway.col-md-5.col-lg-5 {
  background: #fff;
  margin: 15px;
  max-height: 200px;
}
img.rounded.img-fluid {
  width: 204px;
  height: 140px;
  padding: 30px;
  object-fit: cover;
}

.payment-gate-way {
  background: white;
  padding: 0;
  height: 200px;
  overflow: hidden;
}
.payment-gate-way .image {
  height: 156px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.payment-gate-way .paypal {
  width: 154px;
  height: 51px;
  object-fit: cover;
}
.payment-gate-way .square {
  width: 204px;
  height: 97px;
  object-fit: contain;
}
.payment-gate-way .stripe {
  width: 203px;
  height: 97px;
  object-fit: contain;
}
.payment-gate-way .payment-gate-way-footer {
  background-color: #4f46e5;
  color: white;
  border-radius: 0;
}
.payment-gate-way .payment-gate-way-footer .credit {
  font-size: 14px;
  font-weight: 500;
  display: flex !important;
  align-items: center;
}
.addAttendant_btn {
  display: flex;
  align-items: center;
  column-gap: 10px;
}
.addAttendant_btn span {
  font-size: 18px;
  font-weight: 700px;
}
.addAttendant_btn i {
  background-color: #4f46e5;
  width: 40px;
  height: 40px;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  color: white !important;
  margin: 0 !important;
}
.form-switch .form-check-input {
  width: 44px;
  height: 24px;
  margin-right: 10px;
}
.form-check-input:checked {
  background-color: #4f46e5 !important;
  border-color: #4f46e5 !important;
}
.form-check-input:focus {
  border-color: none;
  outline: 0;
  box-shadow: none;
}

.upload-btn-wrapper {
  text-align: center;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.upload-btn-wrapper span {
  font-size: 17px;
  font-weight: 500;
}
.upload-btn-wrapper img {
  width: 145px;
  height: 133px;
  margin-bottom: 10px;
}
.doubleEllipsis i {
  color: #b1b1b1;
}
.singleEllipsis i {
  color: #4f46e5;
}
.files_content p {
  max-width: 450px;
  margin: auto;
  padding-top: 15px;
}
.files_content .image {
  display: flex !important;
}
.top_title p span {
  font-size: 14px;
}
.top_title h4 {
  font-size: 20px;
  font-weight: bold;
}
.outline-none {
  outline: none;
}

.addCustomerInfoModal_dialog {
  max-width: 700px;
  width: 100%;
}
.addCustomerInfoModal_dialog .modal-content {
  overflow: hidden;
  border-radius: 20px;
  border: 0;
}
.tabs_btn input {
  padding: 0 !important;
}
.event_modal_cont {
  height: 550px;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: black !important;
  background-color: transparent;
}
.tabs_btn .nav-link {
  display: block;
  padding: 2px 7px !important;
}

.tabs_btn .form-check-input:checked {
  background-color: #000 !important;
  border-color: #000 !important;
}
.tabs_btn label {
  font-size: 16px;
  font-weight: 400;
  margin-top: 2px;
}

.search-input {
  background-color: white;
  border: 1px solid rgb(197, 196, 196);
  border-radius: 30px;
  height: 60px;
  display: flex;
  align-items: center;
  overflow: hidden;
  padding: 0px 20px;
}

.search-input i {
  margin-top: 4px;
}

.search-input input {
  background-color: transparent;
  border: none;
  padding: 0 6px !important;
  margin: 0 !important;
  font-size: 17px;
  color: black;
}
.search-input input::placeholder {
  color: black;
  font-weight: 400;
}
.search-input input:focus {
  box-shadow: none;
}
.search-result {
  row-gap: 15px;
  display: flex;
  flex-direction: column;
  height: 280px;
  overflow-y: scroll;
}
.but-primary{
  cursor: pointer;
}
.save-btn{
  padding: 6px 34px;
  font-size: 20px;
  margin: 10px 0px;
  border-radius: 21px;
}
.search-result::-webkit-scrollbar {
  width: 5px;
  }
.search-result::-webkit-scrollbar-track-piece {
background: rgb(255, 255, 255) ;
}
.asearch-result::-webkit-scrollbar-thumb {
background: rgb(39, 39, 255) !important;
}

.search-result .single_user {
  border: 1px solid rgb(197, 196, 196);
  padding: 6px 20px;
  cursor: pointer;
}
.search-result .avatar {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(197, 196, 196);
  border-radius: 50%;
  overflow: hidden;
  margin-right: 15px;
}
.search-result .avatar span {
  font-size: 16px;
  text-transform: uppercase;
}
.search-result .info h6 {
  font-weight: 500;
}
.search-result .info span {
  font-weight: 400;
  font-size: 14px;
}

.sun-editor-editable {
  font-size: 16px !important;
}
.se-resizing-bar.sun-editor-common.se-resizing-none {
  display: none !important;
}

/* Gallery Css start */
.trash_btn {
  border: none;
  background-color: red;
  color: white;
  border-radius: 5px;
}
.page {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#form-file-upload {
  height: 10rem;
  max-width: 100%;
  text-align: center;
  position: relative;
}

#form-file-upload-two {
  max-width: 100%;
  text-align: center;
  position: relative;
}

#input-file-upload {
  display: none;
}

#label-file-upload {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-width: 2px;
  border-radius: 1rem;
  border-style: dashed;
  border-color: #cbd5e1;
  background-color: #f8fafc;
}

#label-file-upload.drag-active {
  background-color: #ffffff;
}

.upload-button {
  cursor: pointer;
  padding: 0.25rem;
  font-size: 1rem;
  border: none;
  font-family: "Oswald", sans-serif;
  background-color: transparent;
}

.upload-button:hover {
  text-decoration-line: underline;
}

#drag-file-element {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 1rem;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}
.upload-form {
  max-width: 600px;
  margin: auto;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.upload-form .form-group label {
  font-weight: 500;
}

.upload-form .custom-file-label::after {
  content: "Browse";
}

.upload-form .btn {
  background-color: #5a67d8;
  border: none;
}

.upload-form .btn:hover {
  background-color: #4c51bf;
}
.custom-btn,
.custom-btn-outline {
  border: none;
  border-radius: 50px;
  padding: 15px 35px;
  transition: all 0.3s;
}
.custom-btn-outline {
  border: 1.6px solid rgb(163, 163, 163);
}
.custom-btn {
  background: #4f46e5;
  color: white;
}
.custom-btn:hover {
  background: #5f58e9;
}
.input_group {
  border: 1.6px solid rgb(225, 222, 222);
  display: flex;
  align-items: center;
  max-width: 500px;
  height: 40px;
  width: 100%;
  border-radius: 5px;
  padding: 0px 20px !important;
}
.input_group span i {
  color: rgb(176, 173, 173);
  font-size: 16px;
  margin-right: 10px;
  margin-bottom: 8px;
}
.input_group input {
  background-color: transparent;
  border: none;
  outline: none;
}
.placeholder-font::placeholder {
  font-size: 16px;
}
.section_dropdown {
  min-width: 200px !important;
  width: 100%;
}
.gallery_title h2 {
  font-size: 22px;
}
.gallery_title span {
  font-size: 15px;
  font-weight: 400;
}
.upload_box {
  border: 2px dashed #4c51bf;
  padding: 10px;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 200px;
  width: 100%;
  margin: auto;
  height: 222px;
}
.uploaded_box {
  border: 1px solid #727272;
  padding: 10px;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 200px;
  width: 100%;
  height: 219px;
  margin: auto;
  overflow: hidden;
  position: relative;
}
.uploaded_box_preview {
  padding: 10px;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 200px;
  width: 100%;
  margin: auto;
  overflow: hidden;
  position: relative;
  transition: all 0.3s;
}
.uploaded_box_preview:hover {
  background: white;
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
}
.uploaded_box_preview img {
  height: 120px;
}
.preview_btn {
  color: black !important;
}
.check_input {
  position: absolute;
  top: 9px;
  right: 12px;
}
.uploaded_box img {
  height: 100px;
  width: 100%;
  object-fit: cover;
}
.upload_box img {
  max-width: 150px;
  height: 100px;
  width: 100%;
  object-fit: contain;
}
.upload_box span {
  font-weight: 500;
  font-size: 14px;
}

.photo-wrapper {
  position: relative;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  overflow: hidden;
  margin-bottom: 22px;
}

.photo-wrapper-two {
  position: relative;
  border-radius: 8px;
  overflow: hidden;
  margin-bottom: 22px;
}

.photo-wrapper img {
  display: block;
  width: 100%;
  height: auto;
}

.photo-wrapper .badge {
  font-size: 0.75rem;
  background-color: #4c51bf;
  right: 27px;
  top: 3px;
}

.photo_wrapper_right img {
  object-fit: contain;
}

.primary_img {
  height: 225px;
}

.preview_tab .nav-link {
  font-size: 18px !important;
  font-weight: normal;
  color: black !important;
  transition: all 0.3s;
}
.Main_section {
  display: grid;
  grid-template-columns: 0.4fr 1fr;
  gap: 1rem;
}
.Main_section .side_bar {
  display: grid;
  flex-direction: row;
  justify-items: center;
  align-items: center;
  width: 100%;
  gap: 1rem;
}
.Main_section .side_bar figure {
  width: 102px;
  height: 102px;
  margin: 0;
  opacity: 0.5;
  overflow: hidden;
}
.row_area {
  min-height: calc(100vh - 200px);
  row-gap: 60px;
}
.activeFigure {
  border: 1.6px solid black;
  border-radius: 7px;
  opacity: 1 !important;
}
.Main_section .side_bar figure img {
  width: 100%;
  height: 100%;
}
.main-screen {
  /* display: grid;
  place-items: center;
  order: 1;
  height: 338px;
  overflow: hidden; */
  margin:auto;
}
.main-screen img {
  /* max-width: 100%;
  height: 100%; */
  width: 350px;
  height: 350px;
}
.uploaded_box:hover .image_title {
  text-decoration: underline;
}
.but-primary{
  cursor: pointer;
}

/* modal css *//* CSS for modal */

.custom-modal {
  display: none; /* Hidden by default */
  position: fixed;
  z-index: 99999; /* Ensure modal appears on top of everything */
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6); /* Semi-transparent background */
  overflow: auto;
}

.custom-modal.active {
  display: block; /* Show modal when active */
}

.custom-modal .cu-modal {
  margin: 5% auto; /* Center modal vertically and horizontally */
  padding: 20px;
  position: relative;
}

.custom-modal .cu-modal-content {
  text-align: center;
  overflow: auto; /* Enable scrolling if image exceeds modal size */
}

.custom-modal .cu-close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
  cursor: pointer;
}

.cu-custom-modal .cu-close:hover,
.cu-close:focus {
  color: black;
  text-decoration: none;
}

.custom-modal .cu-modal img {
  height: auto; /* Maintain aspect ratio */
  display: block;
  margin: 0 auto; /* Center image horizontally */
  max-height: 80vh; /* Limit image height to 80% of viewport height */
}


/* Gallery Css end */

@media (max-width: 350px) {
  .tabs_btn label {
    font-size: 14px;
    font-weight: 400;
    margin-top: 4px;
  }
}
@media (max-width: 500px) {
  .Main_section {
    display: flex;
    flex-direction: column-reverse;
  }
  .Main_section .side_bar {
    display: flex;
    justify-content: center;
  }
  .addCustomerInfoModal_dialog {
    margin: auto;
  }
  .event_modal_cont {
    height: auto;
  }
}
@media (max-width: 375px) {
  .add-gallery-set-btn,
  .gallery-delete-btn {
    font-size: 14px;
  }
}

@media (max-width: 400px) {
  .top_title button.but-primary {
    background: #4f46e5;
    max-width: 194px;
    width: 100%;
    height: 46px;
    border-radius: 28px;
    color: #fff;
    font-size: 15px;
    border: none;
    cursor: pointer;
  }
  .m-2.border-custom-colour.d-flex {
    width: 70%;
  }
  .MainLogo {
    width: 100%;
  }
  .company-setting-box {
    padding: 50px 10px !important;
  }
}
@media (max-width: 374px) {
  #submit-btn {
    font-size: 13px;
  }
}
