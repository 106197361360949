/* Styles for the title of the product */
.untitled-product-title {
  font-weight: 800;
  font-size: 20px !important;
}

/* Styles for the preview text */
.preview-text {
  font-size: 17px !important;
}

/* Styles for the first button in the button group */
.bottom-header-main .button_group button:first-child {
  background: transparent;
  border: 2px solid #cbd6e1;
}

/* Styles for the second button in the button group */
.bottom-header-main .button_group button:last-child {
  background: #4f46e5;
  color: white;
}
/* ContractHeader.css */

.editable-title {
  font-size: 1.25rem;
  font-weight: bold;
  border: none;
  border-bottom: 1px solid #ccc;
  background: none;
  width: auto;
  max-width: 100%;
}

.editable-title:focus {
  outline: none;
  border-bottom: 1px solid #000;
}